import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import parser from "html-react-parser"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../css/template/webinar-post.scss"
import BackgroundImage from "gatsby-background-image"
import CallbackForm from "../components/CallbackForm/CallbackForm"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { ApolloProvider } from "@apollo/client"
import { client } from "../hooks/apolloClient"
import "../css/template/past-webinar-post.scss"
import "../components/GatedForm/gatedForm.scss"
import GravityFormForm from "gatsby-plugin-gravity-forms"
import "../css/template/download-post.scss"

const DownloadTemplate = ({ data, pageContext }) => {
  const pageID = pageContext.id
  const [openCallback, setOpenCallback] = useState(false)
  const post = data?.post

  const image = getImage(post?.featuredImage?.node?.localFile)
  const bgImage = convertToBgImage(image)
  const formSettings = data?.wp

  const [showForm, setShowForm] = useState(false)
  const [hasFilledForm, setHasFilledForm] = useState(false)
  useEffect(() => {
    // const storedHasFilledForm = localStorage.getItem(`${pageID}`)
    // const initialHasFilledForm = storedHasFilledForm === "true"

    // setHasFilledForm(initialHasFilledForm)

    if (!hasFilledForm) {
      setShowForm(true)
    }
  })

  return (
    <ApolloProvider client={client}>
      <Layout>
        <div className="post-headline">
          <BackgroundImage className="post-cover" Tag="div" {...bgImage}>
            <div className="container">
              <div
                className="callback-button"
                onClick={() => {
                  setOpenCallback(!openCallback)
                }}
              >
                <p>Request a callback today »</p>
              </div>
              <div className="title-wrapper">
                {post?.title && <h1>{parser(post?.title)}</h1>}
              </div>
            </div>
            <CallbackForm open={openCallback} setOpen={setOpenCallback} />
          </BackgroundImage>
        </div>
        <div className="container main-post-wrapper">
          {post?.blocks?.map((block, index) => {
            if (block.name === "acf/form-file-picker") {
              const downloadResource = block?.downloadResource
              const formData = {
                wp: formSettings,
                wpGfForm: downloadResource?.gfAcfPicker,
              }

              const handleFormSubmit = () => {
                // Redirect to the PDF URL
                window.open(downloadResource.fileSelect?.link, "_blank")
                //save key
                // localStorage.setItem(`${pageID}`, "true")
                setShowForm(false)
                setHasFilledForm(true)
              }

              const successCallback = ({ data: formRes, reset }) => {
                // This will be called after the form is successfully submitted
                handleFormSubmit()
              }

              return (
                <div className="gated-content">
                  <div className="container">
                    <div className="content-wrapper">
                      <div className="form-title">
                        <h1>{downloadResource.title || post?.title}</h1>
                        {downloadResource.content && (
                          <div>{parser(downloadResource.content)}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-wrapper">
                      {showForm ? (
                        <GravityFormForm
                          data={formData}
                          successCallback={successCallback}
                        />
                      ) : (
                        <div className="file-card">
                          <div className="file-logo">
                            <span className="image_wrap">
                              <span class="image-icon icon-animated"></span>
                            </span>
                          </div>
                          <div className="file-content">
                            <div className="file-title">
                              {downloadResource?.title}
                            </div>

                            {downloadResource?.description ? (
                              <div className="file-description">
                                {parser(downloadResource?.description)}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="file-button btn">
                            <a
                              className="download-button"
                              href={downloadResource?.fileSelect?.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Download
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            } else {
              return <div key={index}>{parser(block.originalContent)}</div>
            }
          })}
        </div>
      </Layout>
    </ApolloProvider>
  )
}
export const Head = ({ data }) => (
  <Seo
    title={data?.seo?.seo?.title}
    description={data?.seo?.seo?.metaDesc}
    image={data?.seo?.featuredImage?.node?.sourceUrl}
    props={data?.seo?.seo}
  />
)

export default DownloadTemplate

export const pageQuery = graphql`
  query PostById($id: String!) {
    post: wpDownload(id: { eq: $id }) {
      content
      excerpt
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
              id
            }
          }
        }
      }
      title
      uri
      slug
      blocks {
        originalContent
        ... on WpAcfFormFilePickerBlock {
          name
          downloadResource {
            content
            title
            gfAcfPicker {
              title
              description
              ...GravityFormFields
            }
            fileSelect {
              link
              title
            }
          }
        }
      }
    }
    seo: wpDownload(id: { eq: $id }) {
      featuredImage {
        node {
          sourceUrl
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          srcSet
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          srcSet
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
    wp {
      ...GravityFormSettings
    }
  }
`
